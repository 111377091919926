import React, { Component, Fragment } from "react"
import Layout from "./basic"
import SEO from '../components/seo'
import style from '../components/card/serviceCard.module.scss'
import Button from '../components/button';
import Image from "../components/image";
import Intro from 'components/intro';

export default class ServicesServiceAll extends Component {
	render() {
		let page = null;
		let services = [];
		if (this.props.data.allContentPage && this.props.data.allContentPage.edges.length) page = this.props.data.allContentPage.edges[0].node;
		if (this.props.data.allServicesService && this.props.data.allServicesService.edges.length) services = this.props.data.allServicesService.edges;

		function ServicesServiceSection() {
			const servicesArr = services.map(({ node }, index) => {
				if (index % 2 === 0) {
					return(<div key={node.id} className={style.serviceCard}>
						<div className="grid-container">
							<div className={[style.reverse, "grid-x grid-margin-x"].join(' ')}>
								<div className="cell small-12 medium-10 large-8">
									<div className={style.preTitle}>{ node.preTitle }</div>
									<h2 className={style.title}>{ node.title }</h2>
									<p>{ node.description }</p>
									<Button className={style.button} href={`/services/${node.permalink}/`}>Learn More</Button>
								</div>
								<div className={[(node.icon) ? style.iconHolder : '', "cell small-4 small-offset-4 medium-2 medium-offset-0 large-4"].join(' ')}>
									{ node.icon ? <Image imagePadding="100%" className={style.icon} src={node.icon} alt={node.title} /> : "" }
								</div>
							</div>
						</div>
					</div>)
				} else {
					return(<div key={node.id} className={style.serviceCard}>
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className={[(node.icon) ? style.iconHolder : '', "cell small-4 small-offset-4 medium-offset-0 medium-2 large-4"].join(' ')}>
									{ node.icon ? <Image imagePadding="100%" className={style.icon} src={node.icon} alt={node.title} /> : "" }
								</div>
								<div className="cell small-12 medium-10 large-8">
									<div className={style.preTitle}>{ node.preTitle }</div>
									<h2 className={style.title}>{ node.title }</h2>
									<p>{ node.description }</p>
									<Button className={style.button} href={`/services/${node.permalink}/`}>Learn More</Button>
								</div>
							</div>
						</div>
					</div>)
				}

			}
			)
			return(<Fragment> { servicesArr } </Fragment>)
		}

		return (
			<Fragment>
				<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
					<Intro pullQuote={page.pullQuote} headline={page.headline} pageCopy={page.pageCopy} />
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								<div dangerouslySetInnerHTML={{ __html: (page ? page.content.main : '') }} />
							</div>
						</div>
					</div>
					<ServicesServiceSection />
				</Layout>
				<SEO title={page ? page.pageTitle : ''} />
			</Fragment>
		)
	}
}