import React from "react";
import { graphql } from 'gatsby';

import ServicesServiceAll from "../templates/ServicesServiceAll";

const Services = ({ data, pageContext }) => {
	return(<ServicesServiceAll data={data} />);
}

export default Services;

export const query = graphql`
	query queryServices {
		allServicesService(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}, sort: {fields: title, order: ASC}) {
			edges {
				node {
					id
					preTitle
					title
					permalink
					description
					icon
				}
			}
		}
		allContentPage(filter: {uri: {eq: "/services/"}, lang: {eq: "en"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					id
					uri
					content {
						main
					}
					lang
					pageTitle
					subTitle
					pullQuote
					pageCopy
					headline
					link
					linkText
					danosFoundation
					danosFooterTab
					headerImage
				}
			}
		}
	}
`